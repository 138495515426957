.container {
    gap: 18px;
    display: flex;
    flex-direction: column;
    font-family: "inter";

    &.light {
        background-color: #fff;
    }

    &.dark {
        background-color: #252526;
    }
}

.wrraperContainer {
    border-radius: 6px;
    padding: 12px;

    &.light {
        border: 0.5px solid #4338CA;
    }

    &.dark {
        border: 0.5px solid #527AE6;
    }
}

.headContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.heading {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }

    @media (max-width:576px) {
        font-size: 11px;
    }
}

.modalHeader {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    display: flex;
    align-items: center;

    &.light {
        color: #525252;
    }

    &.dark {
        color: #fff;
    }

    @media (max-width:576px) {
        font-size: 14px;
    }
}

.textarea {
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #171717;
    resize: none;
    width: 100%;
    font-family: "inter";

    &.light {
        background-color: #fff;
        border: 0.5px solid #94A3B8;
        color: #171717;

        &::placeholder {
            color: #525252;
        }
    }

    &.dark {
        background-color: transparent;
        border: 0.5px solid rgba(255, 255, 255, 0.1);
        color: #fff;

        &::placeholder {
            color: #fff;
            opacity: 0.7;
        }
    }

    &::placeholder {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;

        @media (max-width:576px) {
            font-size: 11px;
        }
    }

    @media (max-width:576px) {
        font-size: 11px;
    }

    &.error {
        border-color: transparent;
        border-color: red;
    }
}

.select {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;

    &.light {
        border: 0.5px solid #94A3B8;
    }

    &.dark {
        border: 0.5px solid rgba(255, 255, 255, 0.1);
    }

    @media (max-width:576px) {
        font-size: 11px;
    }
}

.tipsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 0.5px solid #2563EB;
    padding: 12px 8px;
    border-radius: 4px;
    background-color: #DBEAFE;
    font-family: "Inter";

}

.tipsHeading {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #2563EB;
}

.tipsList {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #2563EB;
    display: flex;
    flex-direction: column;
    gap: 5px;
    // width: 272px;
    list-style-type: none;
    padding-left: 3px;
}

.tipItem {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.tipNumber {
    flex-shrink: 0;
    /* Prevents the number from resizing */
    // min-width: 15px; /* Ensures consistent space for numbers */
}

.bulletList {
    list-style-type: disc !important;
    list-style-position: inside; // Ensures bullets appear inside

    .tipItem {
        display: list-item; // Restores list-item behavior
    }

    .tipNumber {
        display: none; // Hides numbering when bullets are active
    }
}