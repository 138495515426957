@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.dropzone {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    svg{
      @media (max-width: break-points.$sm) {
      height: 105px;
      width: 260px;
      }
     }
  
    &.light {
  
      &.isDragOver {
        box-shadow: 0px 0px 0px 0px rgba(colors.$textColorLight, 0.05),
          1px 2px 5px 0px rgba(colors.$textColorLight, 0.05),
          6px 7px 9px 0px rgba(colors.$textColorLight, 0.04),
          13px 16px 12px 0px rgba(colors.$textColorLight, 0.03),
          23px 28px 15px 0px rgba(colors.$textColorLight, 0.01),
          37px 43px 16px 0px rgba(colors.$textColorLight, 0);
      }
    }
  
    &.dark {
      &.isDragOver {
        box-shadow: 0px 0px 0px 0px rgba(colors.$white, 0.05),
          1px 2px 5px 0px rgba(colors.$white, 0.05),
          6px 7px 9px 0px rgba(colors.$white, 0.04),
          13px 16px 12px 0px rgba(colors.$white, 0.03),
          23px 28px 15px 0px rgba(colors.$white, 0.01),
          37px 43px 16px 0px rgba(colors.$white, 0);
      }
    }
  
    @media (max-width: break-points.$sm) {
      border-radius: 10px;
    }
  
    .uploadIcon {
      width: 35px;
      height: 47px;
  
      @media (max-width: break-points.$sm) {
        width: 26px;
        height: 34px;
      }
    }
  
    .message {
      text-align: center;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;
      word-break: break-all;
      margin-top: 20px;
      color: #5F72F3;
  
      @media (max-width: break-points.$sm) {
        margin-top: 0;
        font-size: 25px;
      }
    }
  
    .validationText {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      word-break: break-all;
      color: colors.$lightThemeColor;
      margin-top: 16px;
  
      &.light{
      color: #2A2831;
      }
  
      &.dark{
      color: #FFF;
      }
  
      @media (max-width: break-points.$sm) {
        font-size: 16px;
        margin-top: 8px;
        width: 200px;
      }
  
    }
  
    .clickHereText {
      color: colors.$primaryColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 50px;
  
      @media (max-width: break-points.$sm) {
        font-size: 13px;
        margin-top: 15px;
      }
    }
  }