@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.message {
  white-space: pre-line;
  // word-break: break-all;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;

  &.threadsLight {
    color: #171717 !important;
  }
&.threads{
  font-size: 14px;
}
  &.light {
    color: rgba(colors.$textColorLight, 0.9);

  }

  &.dark {
    color: rgba(colors.$white, 0.9);
  }

  &.IGResponseLoading {
    width: 100%;
    line-height: 24px;
    padding: 0 0 25px 32px;

    @media (max-width: break-points.$lg) {
      padding: 0 0 0 20px;
    }

    @media (max-width: break-points.$sm) {
      padding: 0 0 0 10px;
      font-size: 13px;
      line-height: 20px;
    }

    @media (max-width: 450px) {
      width: 200%;
    }
  }

  &.styleTextArea {
    min-height: unset !important;
    max-height: unset !important;

  }

  .mainMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &.threads{
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
    @media (max-width:520px) {
      gap: 0.8rem;
    }

    @media (max-width:350px) {
      gap: 0.6rem;
    }
  }

  .userMessage {
    border-radius: 5px 20px 20px 20px;
    padding: 10px;

    &.threads {
      border-radius: 4px;
      padding: 12px;
    }

    &.threadsLight {
      background: #EEF2FF;
      border: 0.2px solid rgba(148, 163, 184, 0.50);
    }

    &.light {
      background: #EDF1FE;
    }

    &.dark {
      background: #252526;
    }

    &.mainQuestion {
      width: auto;
    }

    &.sharchat {
      padding: 12px 0px 15px 0px;
      background-color: transparent;
    }

    &.messageContent {
      margin-top: -7px;
    }
  }

  ul {
    padding-left: 1em;

    li {
      position: relative;
      padding-left: 10px;
      margin-bottom: 10px;

      &:before {
        position: absolute;
        top: 0;
        font-size: 28px;
      }
    }
  }

  ol {
    padding-left: 1.6em;

    li {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 10px;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 22px;
  }
}

.answerStyle {
  border-radius: 5px 20px 20px 20px;
  padding: 10px;

  &.light {
    color: rgba(colors.$textColorLight, 0.9);
    background: #F6F6F6;

  }

  &.dark {
    color: rgba(colors.$white, 0.9);
    background: #252526;
  }

  &.threadsLight {
    background: #FFF;
    border-radius: 4px;
    border: 0.2px solid rgba(148, 163, 184, 0.50);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    padding: 12px;
    color: #171717;
  }
  &.threadsDark {
    color: rgba(colors.$white, 0.9);
    background: #252526;
    border-radius: 4px;
    border: 0.2px solid rgba(148, 163, 184, 0.50);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    padding: 12px;
  }
}