.headContainer{
    display: flex;
    justify-content: space-between;
    }
    .text{
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        &.light{
            color:#171717;
            }
            &.dark{
                color:#fff;
            }
        @media (max-width:576px) {
            font-size: 16px;
         } 
    }
    .nameContainer{
        display: flex;
        gap: 12px;
        color: #737373; /* Default inactive color */
        align-items: center;
        &.active {
            &.light{
            color: #4338CA; 
            }
            &.dark{
               color:#527AE6; 
            }
        }
    }
    .pointNumber{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        border: 2px solid #737373;
        border-radius: 50%;
        &.active {
            &.light{
            border-color: #4338CA; 
            }
            &.dark{
                border-color:#527AE6; 
            }
        }
        &.completed {
            &.light{
            background-color: #4338CA !important;
            color: white;
            border-color: #4338CA !important;
            }
            &.dark{
                background-color: #527AE6 !important;
                color: white;
                border-color: #527AE6 !important;
            }
        }
        @media (max-width:576px) {
            font-size: 12px;
         } 
    }
    .nameText{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        @media (max-width:576px) {
            font-size: 14px;
         } 
    }
    .fieldContainer{
        padding:12px;
        gap: 6px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        overflow: hidden;
        &.light{
            border: 0.5px solid #4338CA;
            }
            &.dark{ 
                border: 0.5px solid #527AE6;
            }
    }
    .question{
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        &.light{
        color:#171717;
        }
        &.dark{
            color:#fff;
        }
        @media (max-width:576px) {
            font-size: 11px;
         } 
    }
    .fieldWrapper{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .emojiContainer{
        border-radius: 4px;
        width: 38px;
        height: 33px;
        padding: 7px 10px;
        cursor: pointer;
        display: inline;
        flex-shrink: 0;
        &.light{
            border: 0.5px solid #94A3B8;
        }
        &.dark{
            border: 0.5px solid rgba(255,255,255,0.1);
        }
    }
    .message{
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        font-style: italic;
        color:#737373;
        @media (max-width:576px) {
            font-size: 11px;
         } 
    }
    .btn{
        display: flex;
        gap: 7px;
        align-items: center;
        svg{
            width: 10px;
            height: 8px;
           }
    }
    // .scrollContainer {
    //     overflow-y: auto;
    // }
    .errorText{
        white-space: break-spaces;
        color: rgb(255, 84, 84);
        font-size: 13px;
        margin-top: 2px;
        margin-left: 2px;
    }