@use "../../../scss/variables";
@use "../../../scss/colors";
@use "../../../scss/z-indices";
@use "../../../scss/break-points";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.backdrop {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(25px);
  background-color: rgba(colors.$black, 0.3);
  -moz-background-color: rgba(colors.$black, 0.3);
  pointer-events: auto;
  z-index: z-indices.$backdrop;

  @media (max-width: break-points.$md) {
    display: block;
  }

  @media (max-width: break-points.$sm) {
    display: block;
  }
}

.mainContainer {
  width: variables.$sidebar;
  height: 100%;
  position: fixed;
  z-index: z-indices.$sidebar;
  transition: transform variables.$sb-transition ease;
  transform: translateX(-310px);

  &.isOpen {
    transform: translateX(0);
  }

  &.light {
    background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);

  }

  &.dark {
    background: linear-gradient(180deg, #382E5D 0%, #303E80 51.5%, #383061 99.99%, #313E80 100%);
  }

  &.setting {
    background: colors.$settingSideBarLightMode
  }

  &.settingdark {
    background: colors.$mainViewDarkMode;
  }

  @media (min-width: 769px) {
    &.smallmainContainer {
      width: variables.$miniSidebar;
      height: 100%;
      position: fixed;
      z-index: z-indices.$sidebar;
      transition: transform variables.$sb-transition ease;
      transform: translateX(0px);
    }
  }
}

.container {
  width: variables.$sidebar-box;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  z-index: z-indices.$sidebar;
  transition: transform variables.$sb-transition ease;
  transform: translateX(-300px);
  padding: 25px 15px variables.$pagePadding 15px;
  margin: 13px;
  border-radius: 20px;
  background: #FFFFFF26;
  box-shadow: 0px 620px 174px 0px rgba(46, 55, 127, 0.00), 0px 397px 159px 0px rgba(46, 55, 127, 0.01), 0px 223px 134px 0px rgba(46, 55, 127, 0.05), 0px 99px 99px 0px rgba(46, 55, 127, 0.09), 0px 25px 55px 0px rgba(46, 55, 127, 0.10);

  &.isOpen {
    transform: translateX(0);
    padding: 13px 15px variables.$pagePadding 15px;

    @media (max-width:break-points.$md) {
      padding: 17px 15px variables.$pagePadding 15px;
    }

  }

  &.settingsSidebarContainer {
    @media (max-width:break-points.$sm) {
      padding: 10px 13px 10px 13px;
      margin: 0;
      width: 280px;
      border-radius: 0;
    }
  }

  &.darks {
    background: #1E193C33;

  }

  &.settingdark {
    background: colors.$darkModeSetting;
    box-shadow: none;
  }

  @media (min-width: 769px) {
    &.smallmainContainer {
      width: variables.$minisidebar-box;
      height: 100%;
      position: fixed;
      z-index: z-indices.$sidebar;
      transition: transform variables.$sb-transition ease;
      transform: translateX(0px);
    }
  }


  @media (max-width: break-points.$md) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: break-points.$sm) {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
  }

  .header {
    height: variables.$sidebar-header-height;
    z-index: 20;
    display: flex;

    &.settingsHeader {
      @media (max-width:break-points.$sm) {
        height: auto;
      }
    }

    .logoHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      &.settingsSidebar {
        @media (max-width:break-points.$sm) {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px 0px 10px 12px;
          justify-content: space-between;
          gap: 0px;
        }
      }

      &.isOpen {
        gap: 0;
        justify-content: center;
      }

      &.chatspace {
        justify-content: space-between;
      }

      .logoButton {
        background-color: transparent;
        padding: 0;
        margin: 0;

        .logoTextsmall {
          display: none;

          @media (max-width:break-points.$md) {
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: colors.$darkModeText;
          }

          .mainsidehead {
            display: none;

            .sidebarhead {
              @media (max-width:break-points.$md) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }
            }

            .sidebarSpace {
              @media (max-width:break-points.$md) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
              }
            }

            .smallLogoText {
              background: radial-gradient(61.07% 143.16% at 18.26% 69.36%, colors.$sidebarLogoText 0%, colors.$sidebarLogoText2 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        .logoText {
          font-family: Montserrat;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: colors.$darkModeText;

          .mainsidehead {
            display: none;

            .sidebarhead {
              @media (max-width:break-points.$sm) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 62px;
              }
            }
          }

          &.setting {
            background: radial-gradient(61.07% 143.16% at 18.26% 69.36%, colors.$sidebarLogoText 0%, colors.$sidebarLogoText2 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.chatLogoText {
            font-size: 20px;
          }
        }
      }

      .button {
        border: none;
        margin: 0;
        padding: 0;
        background-color: transparent;

        svg {
          & path {
            fill: colors.$darkModeText;
          }
        }

        &.setting {
          svg {
            & path {
              fill: colors.$primaryColor;
            }
          }
        }

        &.isChatSide {
          padding: 0px 10px;
        }
      }

    }
  }

  .content {
    width: 100%;
    height: calc(100% - variables.$sidebar-header-height);
    background-color: transparent;
    position: relative;

    @media (max-width: break-points.$md) {
      height: calc(100% - 24px);
    }

    .lgContent {
      display: block;
      height: 100%;
      margin-top: 4px;

      @media (max-width: break-points.$md) {
        display: block;
      }

      @media (max-width: break-points.$sm) {
        display: block;
      }
    }

    .mdContent {
      display: none;

      @media (max-width: break-points.$md) {
        display: none;
        height: 100%;
      }

      @media (max-width: break-points.$sm) {
        display: none;
      }
    }

    .smContent {
      display: none;

      .footer {
        width: 100%;
        padding: 18px 0;
      }

      @media (max-width: break-points.$sm) {
        height: 100%;
      }
    }
  }
}

.resizer {
  width: 5px;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0; // Stretch full height
  z-index: 1; // Make sure it's above other content
  background-color: transparent; // Make it invisible or semi-transparent

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); // Light background on hover for visibility
  }
}

.expanded {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Shadow for expanded look
}

.newChatBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 15px;
  height: 40px;
  border-radius: 40px;
  background: rgba(229, 234, 241, 0.30);
  display: none;

  &:hover {
    background: colors.$darkModeText;
    color: #527AE6;

    .btnContent {
      color: #527AE6;

      svg {
        width: 18px;
        height: 16px;
        flex-shrink: 0;

        & path {
          stroke: #527AE6;
        }
      }
    }
  }

  @media (max-width:break-points.$sm) {
    display: block;
  }

  .btnContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: colors.$darkModeText;

    svg {
      width: 18px;
      height: 16px;
      flex-shrink: 0;

      & path {
        stroke: colors.$darkModeText;
      }
    }

    &:hover {
      color: #527AE6;

      svg {
        width: 18px;
        height: 16px;
        flex-shrink: 0;

        & path {
          stroke: #527AE6;
        }
      }
    }

    .text {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.backDropSwitch {
    opacity: 0.4;
    pointer-events: none;
  
}

.logoHeaderlg {
  padding: 5px;

  &.logoHeaderlgChat{
  &:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
  }
  }
  
  &.dopDownOpen {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
  }

  &.dropdownDark {
    background: rgba(104, 103, 140, 0.20);
  }
}

.logoHide {
  display: none;
}

// .disableSidebar{
//   background: rgba(21,21,21,0.9) !important;
// }

.isbackdrop {
  opacity: 0.4;
  pointer-events: none;
}