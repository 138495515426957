@use "../../../../../../scss/colors";

.codespan {
  font-weight: 600;
  font-size: 17px;
  padding: 2px 4px;
  margin: 0 2px;
  border-radius: 6px;

  &.light {
    background-color: colors.$codeBlockLight;
    color: colors.$textColorLight;
  }

  &.dark {
    background-color: colors.$codeBlockBackground;
    color: colors.$white;
  }
}

.href {
  color: colors.$primaryColor;
  text-decoration: underline;
}

.markdown {
  margin: 0;
  padding: 0;
}

.markdown p {
  margin: 0;
}

.markdown ol li p {
  margin-top: -22px;
  padding-left: 17px;
}

.markdown h1{
line-height: 35px !important;
font-size: inherit;
font-weight: inherit;
}

.markdownContainer p,
.markdownContainer ol,
.markdownContainer ul,
.markdownContainer li,
.markdownContainer table,
.markdownContainer tr,
.markdownContainer td,
.markdownContainer th {
  margin: 0 !important;
  padding: 0 !important;
}

.markdownContainer h1, 
.markdownContainer h2, 
.markdownContainer h3, 
.markdownContainer h4, 
.markdownContainer h5, 
.markdownContainer h6 {
  font-size: inherit;
  font-weight: inherit;
}


.markdownContainer ol li p {
  margin-top: -24px !important;
  @media (max-width:576px) {
    margin-top: -20px !important;
  }
  @media (max-width:576px) {
    margin-top: -20px !important;
  }

  @media (max-width: 576px) {
    margin-top: -20px;
  }
}

.markdownContainer ol li h1 {
  line-height: 35px !important;
  
}
.markdownContainer h1 {
  font-size: inherit;
font-weight: inherit;
}

.markdownContainer ol,
.markdownContainer ul {
  margin-top: -30px !important;
  padding-left: 17px !important;
}

.markdownContainer ol li {
  margin-bottom: -20px !important;
  @media (max-width:576px) {
    margin-bottom: -15px !important;
  }
}

.markdownContainer ul li {
  margin-bottom: -15px !important
}

.markdownContainer ul li ul {
  margin-top: -20px !important;
}

/* markdown.module.scss */
.customUl+p,
.customOl+p {
  margin-top: -30px !important;
  @media (max-width:576px) {
    margin-top: -25px !important;
  }
}