@use "../../../../../scss/break-points";
.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    @media (max-width: break-points.$sm) {
        margin-top: 15px;
        gap: 15px;
    }
}
.threadsHeading {
    // padding: 38px 0px 0px 26px;
    display: flex;
    align-items: center;
    gap: 18px;
}
.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    &.light {
        color: #171717;
    }
    &.dark {
        color: #fff;
    }
    @media (max-width: break-points.$sm) {
        font-size: 15px;
    }
}
.countThreads {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    &.light {
        color: #737373;
    }
    &.dark {
        color: #fff;
        opacity: 0.7;
    }
}
.cardContainer {
    display: flex;
    padding: 12px;
    flex-direction: column;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    &.light{
        border: 0.2px solid rgba(148, 163, 184, 0.50);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
        &.hover{
            border: 0.2px solid #4338CA;
            }
    }
    &.dark{
        border: 0.2px solid rgba(148, 163, 184, 0.50);
        background-color: #252526;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
        &.hover{
            border: 0.2px solid #527AE6;
            }
    }
}
.headWrapper {
    display: flex;
    justify-content: space-between;
}
.nameContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}
.cardTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.light {
        color: #171717;
    }
    &.dark {
        color: #fff;
    }
    @media (max-width: break-points.$sm) {
        font-size: 12px;
    }
}
.threadActions {
    display: flex;
    gap: 17px;
    align-items: center;
}
.date {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.light {
        color: #737373;
    }
    &.dark {
        color: #fff;
        opacity: 0.7;
    }
}
.metaInfo {
    display: flex;
    gap: 16px;
    align-items: center;
}
.indicatorContainer {
    display: flex;
    align-items: center;
}
.circleWhite {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    &.light {
        background-color: #fff;
    }
    &.dark {
        background-color: #252526;
    }
}
.circlegrey {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.light {
        background-color: #e0e0e0;
    }
    &.dark {
        background-color: #737373;
    }
    z-index: 1;
}
.circleBlue {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #C7D2FE;
    color: #3730A3;
    z-index: 2;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.25px;
}
.threadsMeta {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 4px;
    &.light {
        color: #737373;
    }
    &.dark {
        color: #fff;
        opacity: 0.7;
    }
    &.hover{
        color: #3730A3;
    }
}
.dot {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.feedbackContainer {
    display: flex;
    gap: 6px;
    align-items: center;
}
.tagBadge {
    display: flex;
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #EEF2FF;
    color: #525252;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.delIcon{
    flex-shrink: 0;
    line-height: 0px;
}