@use "../../../../scss/break-points";

.fullscreenModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    backdrop-filter: blur(2px);
  
    &.light {
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    &.dark {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  
  .fullscreenModalContainer {
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    width: 65vw;
    max-height: 90vh;
    // max-width: 1600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 1000000;
  
    &.light {
      background-color: #FFFFFF;
    }
  
    &.dark {
      background-color: #1F1F1F;
      color: rgba(255, 255, 255, 0.9);
    }
  }
  
  .modalHeader {
    margin: 16px 24px 10px 24px;
    border-bottom: 1px solid #E5E5E5;
  
    .light & {
      border-bottom: 1px solid #E5E5E5;
    }
  
    .dark & {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  
  .fileName {
    margin: 0;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  
    .light & {
      color: #525252;
    }
  
    .dark & {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  
  .closeButton {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  position: relative; /* Add position for z-index to work */
  z-index: 5;  /* Ensure button is above other elements */
  
    svg {
      width: 20px;
      height: 20px;
  
      .light & path {
        fill: #666666;
      }
  
      .dark & path {
        fill: rgba(255, 255, 255, 0.7);
      }
    }
  }
  
  .modalContent {
    margin-bottom: 10px;
    overflow: auto;
    padding: 10px;

  }
  
  /* Loading and error states */
  .loadingContainer, .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;
    text-align: center;
    padding: 20px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  
    .dark & {
      border: 4px solid rgba(255, 255, 255, 0.1);
      border-top: 4px solid #3498db;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .errorMessage {
    color: #DC2626;
    margin: 0;
    font-size: 16px;
  
    .dark & {
      color: #F87171;
    }
  }
  
  .downloadButton {
    background-color: #4338CA;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  /* Styled PDF container - similar to Apple's PDF preview */
  .styledPdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 7px 20px ;

    
  }

  .pdfContainer {
    background: transparent !important; /* Ensures no background */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

  
  .pdfPreviewFrame {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    // box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer; /* Indicate it's clickable */
    // transition: transform 0.2s, box-shadow 0.2s;
    
    // &:hover {
    //   transform: scale(1.01);
    //   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
      
    //   .clickableHint {
    //     opacity: 1;
    //   }
    // }
    
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: -10px;
      top: 10px;
      bottom: 10px;
      width: 20px;
    //   background: linear-gradient(to left, rgba(0,0,0,0.1), transparent);
      border-radius: 0 5px 5px 0;
      transform: skew(-3deg);
    }
    
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      right: -20px;
      top: 20px;
      bottom: 15px;
      width: 20px;
      background: rgba(0,0,0,0.03);
      border-radius: 0 5px 5px 0;
    //   transform: skew(-5deg);
    }
  }
  
  .pdfIframePreview {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }

//   .pdfIframe {
//     display: block;
//     margin: 0 auto; /* Center horizontally */
//     width: 100%; /* Adjust width */
//     max-width: 800px; /* Optional: limit size */
//     height: 90vh; /* Adjust height dynamically */
//     background: black; /* Ensure background color is applied */
// }
.pdfIframe {
    display: block;
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    border: none;  /* Removes any border */
}

  
  .clickableOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: transparent;
  }
  
  .clickableHint {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .pdfOverlayControls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }
  
  .pdfClosePreviewButton {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
    
    svg {
      width: 12px;
      height: 12px;
    }
  }
  
  .pdfPageCount {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
    
    .dark & {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  
  /* Text and CSV previews */
  .textContainer, .csvContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .textHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  .openInNewTabButton {
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #4b5563;
    
    &:hover {
      background-color: #e5e7eb;
    }
    
    .dark & {
      background-color: #374151;
      border-color: #4b5563;
      color: rgba(255, 255, 255, 0.9);
      
      &:hover {
        background-color: #4b5563;
      }
    }
  }
  
  .textContent {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.6;
    padding: 20px;
    margin: 0;
    background-color: #F9FAFB;
    border-radius: 4px;
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  
    .dark & {
      background-color: #111827;
      color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }
  
  .csvTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-top: 10px;
  
    th, td {
      border: 1px solid #E5E7EB;
      padding: 10px 12px;
      text-align: left;
  
      .dark & {
        border: 1px solid #4B5563;
      }
    }
  
    th {
      background-color: #F3F4F6;
      font-weight: 500;
      position: sticky;
      top: 0;
      z-index: 1;
  
      .dark & {
        background-color: #374151;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  
    tr:nth-child(even) {
      background-color: #F9FAFB;
  
      .dark & {
        background-color: #1F2937;
      }
    }
  
    .dark & {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  
  /* Styled download container for unavailable previews */
  .styledDownloadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    text-align: center;
    height: 100%;
    
    .fileIconLarge {
      font-size: 64px;
      margin-bottom: 24px;
      
      svg {
        width: 60px;
        height: 60px;

        @media (max-width: break-points.$sm) {
            width: 50px;
            height: 50px;
        }
      }
    }
    
    .downloadMessage {
    //   color: #6B7280;
    color: #525252;

/* regular/h4 */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
      margin: 0 0 24px;
      
      .dark & {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .fullscreenModalContainer {
      width: 95vw;
      height: 95vh;
    }
    
    .fileName {
      font-size: 22px;
    }
    
    .pdfPreviewFrame {
      width: 95%;
    }
  }

  .codeContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // background-color: #f5f5f5;
    color: #525252;
    
    .dark & {
      background-color: #1e1e1e;
      color: #d4d4d4;
    }
  }
  
  .codeContent {
    padding: 7px 20px;
    font-family: 'Courier New', monospace;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.5;
    overflow: auto;
  }
  
  /* JSON syntax highlighting */
  :global(.json-key) {
    color: #a31515;
    
    .dark & {
      color: #ce9178;
    }
  }
  
  :global(.json-string) {
    color: #0451a5;
    
    .dark & {
      color: #9cdcfe;
    }
  }
  
  /* Markdown container */
  .markdownContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  
  .markdownContent {
    padding: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    white-space: pre-wrap;
    overflow: auto;
  }
  
  /* HTML container and iframe */
  .htmlContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  
  .htmlIframe {
    flex: 1;
    border: none;
    width: 100%;
    height: calc(100% - 40px); /* Account for header */
    background-color: white;
    
    .dark & {
      background-color: #1e1e1e;
    }
  }
  
  /* Improved text container */
  .textContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  
  .textContent {
    padding: 16px;
    font-family: 'Courier New', monospace;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.5;
    overflow: auto;
  }
  
  /* Header for text-based previews */
  .textHeader {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    
    .dark & {
      background-color: #2d2d2d;
      border-bottom-color: #444;
    }
  }
  
  .openInNewTabButton {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    
    &:hover {
      background-color: #e0e0e0;
    }
    
    .dark & {
      border-color: #555;
      color: #d4d4d4;
      
      &:hover {
        background-color: #3d3d3d;
      }
    }
  }

  iframe {
    background: transparent !important;
  }

  .pdfIframeWrapper {
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 60px);
    /* Other styles */
  }

  .fileInfo {
    display: flex;
    flex-direction: column;
  }
  
  .fileName {
    margin-bottom: 4px;
  }
  
  .fileMetadata {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #525252;
    // padding: 4px 0px;
    border-radius: 4px;
    margin-top: -9px;
    margin-bottom: 3px;
    opacity: 0.8;

    @media (min-width: break-points.$md) {
        margin-top: -18px;
    }

    &.dark {
        color: #fff;
        
    }
    
    span {
      margin-right: 4px;
    }
  }
  
  .metadataDot {
    margin: 0 8px 2px 8px;
    font-size: 20px;
  }

  .htmlPreviewContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .htmlPreviewControls {
    display: flex;
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f5f5f5;
  }
  
  .htmlPreviewButton {
    padding: 6px 12px;
    margin-right: 8px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .htmlPreviewButton.active {
    background-color: #e8f0fe;
    border-color: #4285f4;
    color: #1a73e8;
  }
  
  .htmlIframe {
    flex: 1;
    min-height: 300px;
  }