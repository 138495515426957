@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.dropDownContainer {
    &.light {
        background-color: #ffffff;
    }

    &.dark {
        background-color: #252526;
    }
}

.DropDownbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 172px;
    overflow-y: auto;
    position: relative;
    z-index: 10;

    &.threads{
        max-height: 120px;
    }

    &.imageGeneration {
        @media (max-width: break-points.$sm) {
            max-height: 120px;
        }
    }

    &.light {

        &::-webkit-scrollbar {
            width: 12px;
            /* Width of the vertical scrollbar */
            height: 13px;
            /* Keep the height of the scrollbar itself */
        }

        &::-webkit-scrollbar-track {
            background: #fff;
            /* Background color of the scrollbar track */
            border-radius: 10px;
            padding: 4px 0;
            /* Increase padding inside the track to increase its height visually */
        }

        &::-webkit-scrollbar-thumb {
            background: #D8D8D8;
            border-radius: 10px;
            border: 4px solid transparent;
            background-clip: padding-box;
            height: 8px;

        }
    }

    &.dark {
        &::-webkit-scrollbar {
            width: 12px;
            height: 13px;
        }

        &::-webkit-scrollbar-track {
            background: #252526;
            border-radius: 10px;
            padding: 4px 10;
        }

        &::-webkit-scrollbar-thumb {
            background: #848485;
            border-radius: 10px;
            border: 4px solid transparent;
            background-clip: padding-box;
            height: 8px;
        }
    }

    .message {
        width: 100%;
        text-align: center;
        color: colors.$white;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;

        @media (max-width: break-points.$sm) {
            font-size: 14px;
        }
    }
}

.icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    &.threads{
        gap:8px;
    }

}


.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    background-color: transparent;
    border: transparent;

    &:hover {
        &.light {
            background: #EDF1FE;
            border: none;
            border: transparent;
            box-shadow: none;
        }

        &.dark {
            background: #2A3249;
            border: none;
            border: transparent;
            box-shadow: none;
        }

        &.containerHover {
            background: transparent;
        }
    }

    .imagedropdownContainer {
        width: 32px;
        height: 30px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.imageGenerationContainer {
            @media (max-width: break-points.$sm) {
                height: 28px;
            }
        }
    }

    .textContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        @media (max-width: break-points.$sm) {
            margin-left: 15px;
        }

        .modalName {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            @media (max-width: break-points.$sm) {
                font-size: 13px;
            }
        }

        .dropdownModalName {
            font-size: 11px;
            font-weight: 700;
            line-height: 20px;

            &.light {
                color: #2A2831;
            }

            &.dark {
                color: colors.$lightThemeColor;
            }

        }

        .modelCreditPerQuery {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            opacity: 0.6;

            &.dark {
                color: colors.$lightThemeColor;
            }

            @media (max-width: break-points.$sm) {
                font-size: 13px;
            }
        }
    }
}

.imagebackground {
    background-color: white;
    border-radius: 50%;
    padding: 1px;
}

.regenrationContainer {
    display: flex;
    align-items: center;
    height: 24.13px;
    gap: 5px;
    border-radius: 30px;
    padding: 1px 7px;

    &.light {
        background: #F6F6F6;
    }

    &.dark {
        background: #252526;
    }
    &.threads{
        background: transparent !important; 
        padding: 0;
    }
}

.modalName {

    font-size: 10px;
    font-weight: 500;
    line-height: 12.1px;

    &.light {
        color: #2A2831;
    }

    &.dark {
        color: #fff;
    }

    &.threads {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width: break-points.$sm) {
            font-size: 12px;
        }
    }
    &.threadLight{
        color: #737373;
    }
}

.checkboxContainer {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    right: 0px;
    padding: 0px 13px;
}

.checkboxContainer input {
    display: none;
}

.checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid colors.$checkBorder;
    border-radius: 50%;
    margin-left: 7px;
}

.checkboxContainer input:checked~.checkmark {
    background: linear-gradient(90deg, #4693E8 0%, #6C53FF 100%);
    border: 0px;
}

.checkboxContainer input:checked~.checkmark:after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 18 13" fill="none"> <path d="M6.96994 12.5304L0.969937 6.53039C0.676688 6.23714 0.676688 5.76239 0.969937 5.46989L2.03044 4.40939C2.32369 4.11614 2.79844 4.11614 3.09094 4.40939L7.50019 8.81864L15.6594 0.659391C15.9527 0.366141 16.4274 0.366141 16.7199 0.659391L17.7804 1.71989C18.0737 2.01314 18.0737 2.48789 17.7804 2.78039L8.03044 12.5304C7.73794 12.8236 7.26244 12.8236 6.96994 12.5304Z" fill="white"/></svg>');
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.dark {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 18 13" fill="none"> <path d="M6.96994 12.5304L0.969937 6.53039C0.676688 6.23714 0.676688 5.76239 0.969937 5.46989L2.03044 4.40939C2.32369 4.11614 2.79844 4.11614 3.09094 4.40939L7.50019 8.81864L15.6594 0.659391C15.9527 0.366141 16.4274 0.366141 16.7199 0.659391L17.7804 1.71989C18.0737 2.01314 18.0737 2.48789 17.7804 2.78039L8.03044 12.5304C7.73794 12.8236 7.26244 12.8236 6.96994 12.5304Z" fill="black"/></svg>');
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.checkboxDarkContainer {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    right: 0px;
    padding: 0px 13px;
}

.checkboxDarkContainer input {
    display: none;
}

.checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid colors.$checkBorder;
    border-radius: 50%;
}

.checkboxDarkContainer input:checked~.checkmark {
    background: linear-gradient(90deg, #4693E8 0%, #6C53FF 100%);
    border: 0px;
}

.checkboxDarkContainer input:checked~.checkmark:after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 18 13" fill="none"> <path d="M6.96994 12.5304L0.969937 6.53039C0.676688 6.23714 0.676688 5.76239 0.969937 5.46989L2.03044 4.40939C2.32369 4.11614 2.79844 4.11614 3.09094 4.40939L7.50019 8.81864L15.6594 0.659391C15.9527 0.366141 16.4274 0.366141 16.7199 0.659391L17.7804 1.71989C18.0737 2.01314 18.0737 2.48789 17.7804 2.78039L8.03044 12.5304C7.73794 12.8236 7.26244 12.8236 6.96994 12.5304Z" fill="black"/></svg>');
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sharedocCntainer {
    margin-right: 4.5%;

    @media (max-width:break-points.$lg) {
        margin-right: 6.5%;
    }

    @media (max-width:break-points.$sm) {
        margin-right: 10.5%;
    }
}

.regenerateWithOtherModel {
    font-family: Raleway;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 15px 10px 15px;

    &.light {
        color: #2A2831;
        border-bottom: 1px solid #EFEFEF;
    }

    &.dark {
        color: #fff;
        border-bottom: 1px solid #303031;
    }

}

.confirmBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 14px;

    @media (max-width:break-points.$sm) {
        padding-top: 6px;
        padding-bottom: 12px;
    }
}