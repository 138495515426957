@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.nameContainer {
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: colors.$darkModeText;
  background: #5077E4;
  &.threads{
    color: #3730A3;
    background-color: #C7D2FE;
    border: 2px solid #fff;
  }
}
