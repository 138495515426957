@use "../../scss/break-points";

.container {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 0px 26px;
    gap: 14px;
}

.headContainer {
    display: flex;
    gap: 18px;
    align-items: center;
}

// .title {
//     font-size: 20px;
//     font-weight: 500;
//     line-height: 22px;

//     &.light{
//         color: #171717;
//     }
//     &.dark{
//         color: #fff;
//     }

//     @media (max-width: break-points.$sm) {
//         font-size: 15px;
//     }
// }

.countWorkspaces {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    &.light{
        color: #737373;
    }
    &.dark{
        color: #fff;
        opacity: 0.7;
    }

}

.createContainer {
    padding: 14px 12px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    cursor: pointer;
    &.light{
        border: 0.8px dashed #4338CA;
        background-color: #fff;
    }
    &.dark{
        border: 0.8px dashed #527AE6;
        background-color: #252526;
    }
    // width: 276px;
}

.solidBorder {

    &.light{
        border: 0.8px solid #94A3B8;
        background-color: #fff;
    }
    &.dark{
        border: 0.8px solid rgba(255, 255, 255, 0.5);
        background-color: #252526;
    }

    &:hover{
         &.light{
        border-color: #4338CA;
    }
       &.dark{
        border-color: #527AE6;
    }
    }
}

.statement {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    &.light{
        color: #090909;
    }
    &.dark{
        color: #fff;
    }
    @media (max-width: break-points.$sm) {
        font-size: 16px;
    }
}

.linkContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 3px;
}

.link {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-decoration: underline;
    text-decoration-style: solid;
    &.light{
        color: #4338CA;
    }
    &.dark{
        color:#527AE6;
    }
}

.threadsContainer {
    padding: 38px 0px 0px 26px;
    display: flex;
    align-items: center;
    gap: 18px;
}

.backdrop {
    background: rgba(21, 21, 21, 0.5);
    pointer-events: none;
    opacity: 0.4;
}

.rightPanel {
    flex: 2; // Adjust as needed for the CreateWorkspace width
    padding: 1rem;
    background-color: #ffffff;
    border-left: 1px solid #ddd;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.indicatorContainer {
    display: flex;
    align-items: center;
}

.circleWhite {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    &.light{
        background-color: #fff;
    }
    &.dark{
        background-color: #252526;
    }
}

.circlegrey {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.light{
        background-color: #e0e0e0;
    }
    &.dark{
        background-color: #737373;
    }
    z-index: 1;
}

.circleBlue {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #C7D2FE;
    color: #3730A3;
    z-index: 2;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.25px;
}