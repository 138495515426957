@use "../../../../../scss/variables";
@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.container {
  width: 100%;
  height: 100%;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  position: relative;

  .newChatBtn {
    display: flex;
    align-items: center;
    padding: 9px 15px;
    height: 40px;
    border-radius: 40px;
    width: 100%;
    background: rgba(229, 234, 241, 0.30);

    &:hover {
      background: colors.$darkModeText;
      color: colors.$primaryColor;

      .btnContent {
        color: colors.$primaryColor;

        svg {
          & path {
            stroke: colors.$primaryColor;
          }
        }
      }
    }

    .btnContent {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 9px;
      flex-shrink: 0;
      color: colors.$darkModeText;

      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }

      &:hover {
        color: colors.$primaryColor;

        svg {
          & path {
            stroke: colors.$primaryColor;
          }
        }
      }

      .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-right: 7px;
      }
    }
  }

  .content {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
    }

    &.backdrop {
      opacity: 0.4px;
    }

    .emptySearchMessage {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: colors.$darkModeText;
    }
  }

  .footer {
    width: 100%;
    padding: 18px 0;
    border-top: 1px solid colors.$chatFooterBorderTop;

    .itemContainer {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      color: colors.$darkModeText;

      .itemText {

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }

      .usageTag {

        border-radius: 300px;
        padding: 1px 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;

        &.light {
          background-color: colors.$darkModeText;
          -moz-background-color: colors.$darkModeText;
          color: colors.$textColorLightMode;
        }

        &.dark {
          background-color: #2A274A;
          -moz-background-color: #2A274A;
          color: white;
        }
      }
    }
  }

  .Hidefootersetting {
    display: none;
  }

  .themeallow {
    width: 100%;

  }
}

@keyframes moveToRight {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

.historyIconContainer {
  display: flex;
  height: 40px;
  padding: 9px 16px;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.folderHeading{
  gap: 6px;
  padding: 9px 16px 9px 14px;
  width: 100%;
}

.historyHeading {
  width: 100%;
  color: #FFF;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &.chatFolder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: calc(100% - 122px);
  }
}

.deleteHistory {
  background-color: #DF3A32;
  border-radius: 50px;
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  cursor: pointer;
}

.delChat {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 18px;
  cursor: pointer;

  &.closeHistory {
    left: 14px;
  }
}

.deletedynamic {
  right: 3px;
}

.themeContainer {
  display: flex;
  align-items: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 620px 174px 0px rgba(46, 55, 127, 0.00), 0px 397px 159px 0px rgba(46, 55, 127, 0.01),
    0px 223px 134px 0px rgba(46, 55, 127, 0.05),
    0px 99px 99px 0px rgba(46, 55, 127, 0.09), 0px 25px 55px 0px rgba(46, 55, 127, 0.10);
}

.isbackdrop {
  opacity: 0.4;
  pointer-events: none; // Disables all interactions
}

.folderIcon {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #9699EE;
  cursor: pointer;

  &.dark {
    background: #68678D;
  }

}

.scrollBar{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &.emptyFold{
    overflow-y: hidden;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}