@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.MdNavhead {
    padding: 12px 18px;
    border-radius: 10px;
    margin-top: 60px;
    margin-bottom: 20px;

    &.pendingInvite{
        margin-top: 12px;
        padding: 11px 21px;
        margin-bottom: 8px;
    }

    &.light{
        background-color: #fff;
    }
    &.dark{
        background-color: #252526;
    }

    .MdText {
        display: flex;
        flex-direction: column;
        gap: 9px;
        width: 80%;

        @media (max-width: break-points.$sm) {
            width: 263px;
        }
    }

    .title {
        font-family: Raleway;
        font-size: 23px;
        font-weight: 700;
        line-height: 30px;

        &.light {
            color: colors.$textColorLightMode;
        }

        &.dark{
            color: #FFFFFF;
        }

        &.pendingInvite {
            font-family: Raleway;
            font-size: 13px;
            font-weight: 700;
            line-height: 24px;

        }
    }

    .text {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        opacity: 70%;

        @media (max-width: break-points.$sm) {
            font-size: 11px;
            line-height: 15px;
        }

        &.light {
            color: colors.$textColorLightMode;
        }

        &.dark{
            color: #FFFFFF;
        }
    }
}


.btnContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    &.light{
        color: colors.$darkModeText;
    }
    &.dark{
       color: #181819;
    }
  
    .btntext {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  
    .icon {
      width: 22px;
      height: 22px;
    }
  }
  