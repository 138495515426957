@use "../../../../scss/variables";
@use "../../../../scss/break-points";
@use "../../../../scss/z-indices";
@use "../../../../scss/colors";

.footer {
  border-radius: 0 0 20px 20px;
  height: variables.$chatFooter;
  padding: 20px 82px;
  box-sizing: border-box;
  position: fixed;
  bottom: 5px;
  right: variables.$pagePadding;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: calc(100% - (variables.$pagePadding * 2));
  transition: width variables.$sb-transition ease;

  @media (max-width: break-points.$sm) {
    right: 25px;
  }

  @media (max-width: 360px) {
    right: 22px;
  }

  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: #181819;
  }

  &.FileFooter {
    height: 84px;
  }

  &.isSidebarOpen {
    width: calc(100% - (variables.$sidebar + variables.$pagePadding));
    transition: width variables.$sb-transition ease;

    @media (max-width: break-points.$md) {
      width: 100%;
    }
  }

  &.isSideBarClose {
    @media (min-width: 775px) {
      width: calc(100% - (variables.$miniSidebar + variables.$pagePadding));
      transition: width variables.$sb-transition ease;
    }
  }

  &.ReceiveShareChatFooter {
    width: calc(100% - 70px);
    height: 100px;

    @media (max-width:450px) {
      height: 120px;
    }
  }

  &.shareChat {
    background-color: colors.$shareChatLight;
  }

  &.shareChatFooter {
    padding-right: 10px;
    padding-left: 10px;

    @media (max-width: break-points.$sm) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: break-points.$lg) {
    padding: 20px;
  }

  @media (max-width: break-points.$md) {
    width: calc(100% - variables.$pagePadding * 2);
  }

  @media (max-width: break-points.$sm) {
    padding: 12px 0px;
    height: variables.$chatFooterSM;
    width: calc(100% - 48px);
  }

  &.IGFooter {
    height: variables.$IGChatFooter;

    @media (max-width: break-points.$sm) {
      height: variables.$IGChatFooterSM;
    }
  }

  &.IChatFooter {
    height: 76px;

    @media (max-width: break-points.$sm) {
      height: variables.$IGChatFooterSM;
    }
  }

  &.footerResizer {
    right: 0;
    padding: 20px 22px;
    ;
  }

  .IGOptionsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 9px;
    transition: padding variables.$sb-transition ease;

    &.light {
      color: colors.$textColorLightMode;
      background: rgb(255, 255, 255);
    }

    &.dark {
      color: #527AE6;
      background-color: #181819;

    }

    &.isSidebarOpen {
      padding: 4px 9px;
      transition: padding variables.$sb-transition ease;

      @media (max-width: 1120px) {
        display: flex;
        width: 206px;
        height: 139px;
        padding: 11px 24px;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-shrink: 0;
        border-radius: 20px;

        &.light {
          box-shadow: 0px 33px 9px 0px rgba(237, 237, 237, 0.01),
            0px 21px 8px 0px rgba(237, 237, 237, 0.11),
            0px 12px 7px 0px rgba(237, 237, 237, 0.38),
            0px 5px 5px 0px rgba(237, 237, 237, 0.65),
            0px 1px 3px 0px rgba(237, 237, 237, 0.75);
        }

        &.dark {
          box-shadow:
            0px 33px 9px 0px rgba(0, 0, 0, 0.2),
            0px 21px 8px 0px rgba(0, 0, 0, 0.25),
            0px 12px 7px 0px rgba(0, 0, 0, 0.35),
            0px 5px 5px 0px rgba(0, 0, 0, 0.5),
            0px 1px 3px 0px rgba(0, 0, 0, 0.7);
        }
      }
    }

    @media (max-width: break-points.$sm) {
      top: 12px;
      padding: 2px 0px;
    }

    @media (max-width: 335px) {
      display: flex;
      width: 185px;
      height: 100px;
      padding: 4px 24px;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;
      flex-shrink: 0;
      border-radius: 20px;

      &.light {
        box-shadow: 0px 33px 9px 0px rgba(237, 237, 237, 0.01),
          0px 21px 8px 0px rgba(237, 237, 237, 0.11),
          0px 12px 7px 0px rgba(237, 237, 237, 0.38),
          0px 5px 5px 0px rgba(237, 237, 237, 0.65),
          0px 1px 3px 0px rgba(237, 237, 237, 0.75);
      }

      &.dark {
        box-shadow:
          0px 33px 9px 0px rgba(0, 0, 0, 0.2),
          0px 21px 8px 0px rgba(0, 0, 0, 0.25),
          0px 12px 7px 0px rgba(0, 0, 0, 0.35),
          0px 5px 5px 0px rgba(0, 0, 0, 0.5),
          0px 1px 3px 0px rgba(0, 0, 0, 0.7);
      }
    }

    .IGOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      &.isSidebarOpen {
        @media (max-width: 1120px) {
          width: 100%;
          flex-direction: column;
          gap: 0px;
        }
      }

      @media (max-width: break-points.$sm) {
        gap: 0px;
      }

      @media (max-width: 335px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;

        &.isSidebarOpen {
          @media (max-width: 1120px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
        }

        @media (max-width: break-points.$sm) {
          gap: 5px;
        }

        @media (max-width:335px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          @media (max-width: break-points.$sm) {
            font-size: 12px;
          }

          @media (max-width: 448px) {
            display: none;
          }

          @media (max-width: 335px) {
            display: block;
          }
        }
      }
    }

    .imageCredits {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      &.light {
        color: #527AE6;
        opacity: 0.9;
      }

      &.dark {
        color: #527AE6;

      }

      @media (max-width: break-points.$sm) {
        font-size: 11px;
        padding: 0;
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    z-index: 9998;

    @media (max-width: 998px) {
      gap: 7px;
    }

    @media (max-width: break-points.$md) {
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }
}

.chatfooterPromptbtn {
  margin-bottom: 7px;

  @media (max-width: break-points.$sm) {
    margin-bottom: 9px;
  }

  svg {
    @media (max-width: break-points.$sm) {
      width: 21px;
      height: 20px;
    }

    @media (max-width:355px) {
      width: 19px;
      height: 20px;
    }
  }
}

.chatfooterbtn {
  cursor: pointer;

  width: 55px;
  height: 52px;
  border-radius: 100%;

  &.light {
    background: #EDF1FE;
  }

  &.dark {
    background: #252526;
  }

  @media (max-width: break-points.$sm) {
    width: 47px;
    height: 47px;
  }

  @media (max-width: 412px) {
    margin-bottom: 0px;
  }

  @media (max-width: 355px) {
    width: 39px;
    height: 42px;
  }

  &.imagegeneration {
    width: 52px;
    height: 52px;

    &.light {
      background: #EDF1FE;
    }

    &.dark {
      background: #252526;

    }

    @media (max-width: break-points.$sm) {
      width: 47px;
      height: 47px;
    }
  }

  &.privateImagegeneration {
    background-color: #6C56FF;
  }
}

.check {

  display: flex;
  align-items: center;
  margin-top: 3px;
}

.check input[type="checkbox"] {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-bottom: 3.3px;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

}

.check input[type="checkbox"]:checked {}

.check input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  font-weight: bold;
}

.lightCheckbox {
  border: 1.5px solid #252526;
  color: #252526;
}

.darkCheckbox {
  border: 1.5px solid #fff;
  color: #fff;
}



.imagegenerationIcon {
  @media (max-width: break-points.$sm) {
    height: 45px;

    svg {
      height: 19px;
      width: 19px;

    }
  }

  @media (max-width:355px) {
    height: 42px;

    svg {
      height: 18px;
      width: 18px;

    }
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &.ImaePreview {
    width: 100px;
    height: auto;
  }

  &.imagesPrev {
    padding: 20px;

    @media (max-width: break-points.$sm) {
      padding: 27px 10px 0px 10px;
    }
  }
}

.svgCrossIcon {
  display: flex;
  cursor: pointer;
  float: right;
  position: absolute;
  right: -15px;
  top: -20px;
}


.IGOptionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: -25px;
  right: 0;
  padding: 0 163px 0px 82px;
  transition: padding variables.$sb-transition ease;

  &.light {
    color: #527AE6;
    opacity: 0.9;
  }

  &.isPrivateChat {
    color: colors.$textColorLightMode;
    opacity: 0.9;
  }

  &.dark {
    color: #527AE6;

  }

  &.isSidebarOpen {
    padding: 0 163px 0 82px;
    transition: padding variables.$sb-transition ease;
  }

  @media (max-width: break-points.$lg) {
    top: -25px;
    padding: 0 93px 0 20px;
  }

  @media (max-width: break-points.$sm) {
    top: 12px;
    padding: 0 50px 0 0;
  }

  @media (max-width: 375px) {
    top: 6px;
    padding: 0 50px 0 0;
  }

  .IGOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }

  .imageCredits {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
  }
}

.IIGOptionContainer {
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  top: -20px;
  right: 0;
  transition: padding variables.$sb-transition ease;

  &.light {
    color: #527AE6;
    opacity: 0.9;
  }

  &.isPrivateChat {
    color: colors.$textColorLightMode;
    opacity: 0.9;
  }

  &.dark {
    color: #527AE6;

  }

  &.isSidebarOpen {
    transition: padding variables.$sb-transition ease;
  }

  @media (max-width: break-points.$lg) {
    top: -20px;
    padding: 0 20px;
  }

  @media (max-width: break-points.$sm) {
    top: 12px;
    padding: 0 0px;
  }

  @media (max-width: 375px) {
    top: 6px;
    padding: 0 0px;
  }

  .IGOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }

  .imageCredits {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
  }
}

.footerIconWrapper {
  cursor: pointer;
  margin-bottom: 7.5px;

  @media (max-width:break-points.$sm) {
    margin-bottom: 9px;
  }

  svg {

    @media (max-width:break-points.$sm) {
      height: 25px;
      width: 18px;
    }

    @media (max-width:355px) {
      width: 16px;
      height: 22px;
    }
  }
}

.dropdown {
  // position: relative;
  position: absolute;
  border-radius: 10px;
  width: 181px;
  bottom: 79px;
  margin-left: 51px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  z-index: 9999;

  // overflow-y: auto;
  // max-height: -webkit-fill-available;
  // overflow-x: hidden;
  &.conversation {
    bottom: auto;
    margin-left: 78px;

    @media (max-width:break-points.$sm) {
      margin-left: 65px;
    }

    @media (max-width:460px) {
      margin-left: 0px;
    }
  }
&.chatPage{
  bottom: 79px;
  margin-left: 78px;
  @media (max-width:break-points.$sm) {
    bottom: 51px;
  }
  @media (max-width:460px) {
    margin-left: 0px;
  }
}
  &.chat {
    bottom: 50px;
    margin-left: 78px;

    @media (max-width:break-points.$sm) {
      margin-left: 65px;
    }
    @media (max-width:460px) {
      margin-left: 0px;
    }
  }

  &.light {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &.dark {
    background: #252526;
    color: white;
  }

  // &.conversation {
  //   margin-left: 123px;
  //   top: 12.4rem;
  //   bottom: auto;
  //   @media (max-width:break-points.$sm) {
  //     margin-left: 106px;
  //   }
  // }

  @media (max-width:1187px) {
    font-size: 13px;
    width: 171px;
  }

  @media (max-width:1050px) {
    font-size: 12px;
    width: 162px;
  }

  @media (max-width:break-points.$md) {
    font-size: 11px;
    width: 152px;
    margin-left: 46px;
  }

  @media (max-width:break-points.$sm) {
    font-size: 14px;
    width: 173px;
    // bottom: 89px;
    margin-left: 20px;
  }

  @media (max-width:389px) {
    font-size: 12px;
    width: 152px;
    margin-left: 0px;
  }
}

// .dropdown {
//   position: absolute;
//   border-radius: 10px;
//   width: 181px;
//   font-family: Raleway;
//   font-size: 14px;
//   font-weight: 500;
//   overflow-y: auto;
//   max-height: -webkit-fill-available;
//   overflow-x: hidden;
//   &.conversation {
//         margin-left: 78px;
//         @media (max-width:break-points.$sm) {
//           margin-left: 65px;
//         }
//       }
//   &.bottom {
//     // bottom: 79px;
//   }

//   &.top {
//     // top: 10px; // Adjust as needed
//     // transform: translateY(-100%);
//   }

//   &.light {
//     background-color: white;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   }

//   &.dark {
//     background: #252526;
//     color: white;
//   }
// }

.dropdownOption {
  padding: 8px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    @media (max-width:389px) {
      height: 19px;
      width: 19px;
    }
  }

  @media (max-width:389px) {
    padding: 8px 12px;
  }
}

.selected {
  width: 100%;
  height: 60px;

  &.light {
    background: #EDF1FE;
  }

  &.dark {
    background: #343A75;
  }

  @media (max-width:break-points.$md) {
    height: 56px;
  }

  @media (max-width:break-points.$sm) {
    height: 53px;
  }

  @media (max-width:389px) {
    height: 50px;
  }
}

.outputformat {
  // position: fixed;
  position: absolute;
  border-radius: 10px;
  width: 203px;
  max-height: 440px;
  margin-left: 193px;
  bottom: 0px;
  font-family: Raleway;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 40px;

&.chat{
  max-height: 306px;
}
  &.light {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &.dark {
    background: #252526;
    color: white;
  }

  @media (max-width:1187px) {
    font-size: 13px;
    width: 185px;
    margin-left: 183px;
  }

  @media (max-width:1050px) {
    font-size: 12px;
    width: 170px;
    margin-left: 173px;
  }

  @media (max-width:break-points.$md) {
    font-size: 11px;
    width: 164px;
    margin-left: 162px;
  }

  @media (max-width:break-points.$sm) {
    font-size: 14px;
    width: 150px;
    margin-left: 183px;
    line-height: 33px;
    max-height: 400px;
  }

  @media (max-width:389px) {
    font-size: 12px;
    width: 130px;
    margin-left: 158px;
    max-height: 375px;
    line-height: 28px;
  }
}

.outputFormatLabel {
  border-radius: 2px;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline;
  padding: 3px 5px;
  flex-shrink: 0;

  &.light {
    background: #B1C6FF;
  }

  &.dark {
    background: #5F6CE5;
  }

  &.darkSelected {
    background: white;
    color: #5A73F4;
  }

  @media (max-width:break-points.$lg) {
    font-size: 10px;
    padding: 2px 4px;
  }

  @media (max-width:break-points.$md) {
    font-size: 9px;
  }

  @media (max-width:break-points.$sm) {
    font-size: 8px;
  }
}

.selectedFormat {
  display: flex;
  align-items: center;

  &.light {
    color: #2A2831;
  }

  &.dark {
    color: white;
  }
}

.labellink {
  flex-direction: column;
  gap: 2px;
}

.RememberSetting {
  display: flex;
  align-items: center;
  height: 54px;
  width: 173px;
  margin-left: 9px;

  @media (max-width:1187px) {
    width: 162px;
  }

  @media (max-width:1050px) {
    width: 150px;
  }

  @media (max-width:break-points.$md) {
    width: 147px;
    height: 50px;
  }

  @media (max-width:break-points.$sm) {
    width: 156px;
    height: 48px;
  }

  @media (max-width:389px) {
    height: 44px;
  }

  &.light {
    color: #2A2831;
  }

  &.dark {
    color: #fff;
  }
}

.isbackdrop {
  opacity: 0.7;
  pointer-events: none;
}