@use "../../scss/colors";
@use "../../scss/variables";
@use "../../scss/z-indices";
@use "../../scss/break-points";

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: colors.$modalBackDrop;

  &.errorBackdrop {
    background: rgba(21, 21, 21, 0.50);
  }

  &.errorBackdropLight {
    background: rgba(21, 21, 21, 0.80);
  }

  &.uploadDoc {
    background: rgba(255, 255, 255, 0.50);
  }

  &.uploadDocDark {
    background: rgba(21, 21, 21, 0.50);
  }
}

.modalPageContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000009;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeIn 0.2s ease-out;
  display: flex;
  overflow-y: auto;

  &.postionTop {
    bottom: auto;
  }
}

.postionTop {
  bottom: auto;
}

.modalPanel {
  margin: auto;
}

.card {
  box-sizing: border-box;
  border-radius: 20px;
  backdrop-filter: blur(25px);
  max-width: calc(100vw - variables.$spacing-lg * 2);

  &.light {
    background-color: colors.$dark-blue-150;
    -moz-background-color: colors.$dark-blue-150;
    color: colors.$textColorLightMode;
    border: 1px solid colors.$modalBorder;
  }

  &.dark {
    color: colors.$darkModeText;
    border: 1px solid colors.$modalBorder;
    background: colors.$modalDarkModeBackground;
  }

  &.isPadding {
    padding: 16px 20px;
  }

  &.xs {
    width:  variables.$width-modal-xs;

    @media (max-width: break-points.$sm) {
      width: 288px;
    }
  }

  &.ErrorModelLight{
    background-color: colors.$darkModeText;
    -moz-background-color: colors.$darkModeText;
    color: colors.$textColorLightMode;
    border: 1px solid colors.$modalBorder;
  }

  &.isDrag {
    backdrop-filter: blur(0px);
    // width: variables.$width-modal-error;
  }

  &.isErrorModel{
    width: variables.$width-modal-error;
  }

  &.sm {
    width: variables.$width-modal-sm;
  }

  &.md {
    width: variables.$width-modal-md;
  }

  &.lg {
    width: variables.$width-modal-lg;
  }

  &.xl {
    width: variables.$width-modal-xl;
  }

  &.workSpace {
    border-radius: 8px;
    width: variables.$width-modal-workSpace;
    &.light{
      background-color: #fff;
    }
    &.dark{
      background-color: #262626;
    }
  }

  &.adminErrModel {
    width: 530px;
  }

  &.chatSettingSize {
    width: 718px;
    height: 361px;
    padding: 16px 38px;

    @media (max-width:break-points.$sm) {
      height: auto;
      padding: 16px 20px;
    }
  }

  &.editEmail {
    width: 652px;
    height: 145px;
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 5px;
    gap: 18px;

    @media (max-width: 576px) {
      height: auto;
    }
  }

  &.editConfirmationEmail {
    width: 861px;
    height: 504px;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 20px;

    @media (max-width: 1250px) {
      width: 721px;
      height: 435px;
    }

    @media (max-width: 576px) {
      width: 621px;
      height: auto;
    }
  }

  &.urlPrompt {
    width: 350px;
    height: 190px;
  }

  &.updateEmailLight {
    background-color: #fff;
  }

  &.updateBorderRadius{
    border-radius: 5px;
  }

  &.deActivateAccount {
    width: 652px;
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 5px;
    gap: 18px;
  }

  &.deActivateAccountConfirm{
    width: 861px;
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 5px;
  }

  &.deactivateOptions{
    width: 621px;
    border-radius: 5px;
    padding: 20px;
  }
}

.cardTitle {
  color: colors.$black300;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 26px 0 35px;
}

