.rightSide {
    border: 0.6px solid #94A3B8;
    width: 100%;
    border-radius: 8px;

    &.light {
        background: #FFFFFF;
        border: 0.6px solid #94A3B8;
    }

    &.dark {
        background: #252526;
        border: 0.6px solid rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 1025px) {
        position: absolute;
        right: 23px;
        width: 34%;
    }

}

.KnowledgeContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;
    border-radius: 8px;
    padding: 16px;

}

.infotitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
}

.workspace {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
}

.attachSources {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 4px;
    align-items: center;
  
    @media (max-width: 1320px) {
        flex-direction: column;
        align-items: start;
    }
  
    &.Adjustment{
      @media (max-width: 1460px) {
        flex-direction: column;
        align-items: start;
      }
    }
  
    &.Adjustposition {
      flex-direction: column;
      align-items: start;
    }
  
    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
}

.allSources {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 4px;

    &.light {
        color: #4338CA;
    }

    &.dark {
        color: #527AE6;
    }
}

.DropDownbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    padding: 6px;
    gap: 6px;
}

.options {
    padding: 8px 4px;
    border-bottom: 0.4px solid var(--light-border-c10, #94A3B8);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.light {
    color: #171717;
    }

    &.dark {
        color: #fff;
    }
}
.miniRightSide{
    border: 0.6px solid #94A3B8;
    width: 100%;
    border-radius: 8px;
    position: absolute;
    right: 23px;

    &.light {
        background: #FFFFFF;
        border: 0.6px solid #94A3B8;
    }

    &.dark {
        background: #252526;
        border: 0.6px solid rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 1025px) {
        position: absolute;
        right: 23px;
        
    }
}
.knowledgeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    @media (max-width: 1300px) {
      align-items: start;
  }
  
    &.Adjustment{
      @media (max-width: 1460px) {
        align-items: start;
      }
    }
  
    &.Adjustposition {
      align-items: start;
    }
  }

  .headContainer {
    display: flex;
    gap: 4px;
    align-items: center;
  
    @media (max-width: 1300px) {
        flex-direction: column;
        align-items: start;
    }
  
    &.Adjustment{
      @media (max-width: 1460px) {
        flex-direction: column;
        align-items: start;
      }
    }
  
    &.Adjustposition {
      flex-direction: column;
      align-items: start;
    }
  
  }