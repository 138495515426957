.container{
    margin-top: 27px;
}
.footer{
    bottom: 0px;
    padding: 0px 35px 28px 31px;
    width: 100%;
    position: sticky;

    @media (max-width: 1024px) {
      padding: 0px 35px 28px 31px;
      width: 100%;
    }
    @media (max-width: 576px) {
        padding: 0;
        width: 100%;
        position: fixed;
      }
}
.chathistory{
height: 100%;
overflow-y: auto;
margin-right: 27px;
max-height: calc(100vh - 132px);
@media (max-width: 1440px) {
  max-height: calc(100vh - 280px); 
} 
@media (max-width: 1024px) {
  max-height: calc(100vh - 308px); 
}
@media (max-width: 992px) {
  max-height: calc(100vh - 321px); 
}
@media (max-width: 768px) {
  max-height: calc(100vh - 301px); 
}
@media (max-width: 576px) {
  max-height: calc(100vh - 280px);
  margin-right: 5px; 
}
}
.text {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  
    &.light {
      color: #4338CA;
    }
  
    &.dark {
      color: #527AE6;
    }
  
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  .addSourcesContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }
  .ConversationContainer {
    flex-direction: column !important;
    width: 100%;
  }