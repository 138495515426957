@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.container {
  width: 100%;
  padding: 10px 40px 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: break-points.$lg) {
    padding: 10px 20px;
  }

  @media (max-width: break-points.$sm) {
    padding: 10px 23px;
  }
  &.threads{
    padding: 6px 5px 0px 34px;
    @media (max-width: break-points.$sm) {
      padding:0px 8px 0px 13px;
    }
  }
}

.leftContainer {
  width: 100%;
  display: flex;
  flex-direction: row;

  .assistanceIconAnimation {
    animation: rotateAnimation 1s linear infinite;
    border-radius: 10px;
    max-width: 32px;
    max-height: 31px;
    min-width: 31px;
    min-height: 32px;

  }
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 5px 20px;
  width: fit-content;

  &.threads{
    padding: 0 0 5px 8px;
  }
  &.chatEdit {
    width: 100%;
  }

  @media (max-width: break-points.$lg) {
    padding: 0 0 0 20px;
  }

  .fileText {
    white-space: pre-line;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-top: 7px;
    -webkit-overflow-scrolling: touch;
    display: flex;
    gap: 15px;
    margin: 0 0 0 25px;
    overflow-x: auto;
    overflow-x: auto;
    white-space: nowrap;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }

    a {
      opacity: 0.9;
      color: colors.$primaryColor;

      .docIcon {
        margin-bottom: -6px;
        width: 24px;
        height: 24px;

        @media (max-width: break-points.$sm) {
          margin-bottom: -4px;
          width: 18px;
          height: 18px;
        }
      }

      .answerFile {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.light {
          color: colors.$textColorLightMode;
        }

        &.dark {
          color: colors.$lightThemeColor;
        }
      }
    }
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px 20px 92px;

  &.threads{
    // padding: 0 5px 20px 92px;
    padding: 3px 40px 20px 77px;
    gap:14px;
    @media (max-width: break-points.$lg) {
      padding: 3px 20px 20px 77px;
    }
    @media (max-width: break-points.$sm) {
      padding: 3px 15px 20px 58px;
    }
  }
  .icon {
    cursor: pointer;
  }

  @media (max-width: break-points.$lg) {
    padding: 0 20px 20px 76px;
  }
  @media (max-width: break-points.$md) {
    padding: 0 20px 20px 71px;
  }
  @media (max-width: break-points.$sm) {
    padding: 0 15px 20px 73px;
  }
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px 12px 92px;

  &.threads{
    padding: 0 5px 12px 72px;
    @media (max-width: break-points.$sm) {
      padding: 5px 14px 10px 53px;
    }
  }
  @media (max-width: break-points.$lg) {
    padding: 0 20px 12px 71px;
  }

  @media (max-width: break-points.$sm) {
    padding: 40px 23px 10px 74px;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fileText {
  white-space: pre-line;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 24px;
  }

  a {
    opacity: 0.9;
    color: colors.$primaryColor;

    .docIcon {
      margin-bottom: -6px;
      width: 24px;
      height: 24px;

      @media (max-width: break-points.$sm) {
        margin-bottom: -4px;
        width: 18px;
        height: 18px;
      }
    }

    .answerFile {
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.light {
        color: colors.$textColorLightMode;
      }

      &.dark {
        color: colors.$lightThemeColor;
      }
    }
  }
}

.EditButton {
  background-color: rgba(124, 77, 255, 0.1);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  // &.threads{
  //   background-color: transparent;
  //   border-radius: none;
  //   width: auto;
  //   height: auto;
    
  // }
  &.light {
    background: #EDF1FE;
  }

  &.dark {
    background: #252526;
  }
}

.fileMessageContainer {
  width: 100%;
  display: flex;
  overflow-x: auto;

  &.chatEdit {
    width: 100%;
  }

  &.docPadding {
    padding: 0 0 0 69px;

    @media (max-width: break-points.$lg) {
      padding: 0 0 0 56px;
    }

    @media (max-width: break-points.$sm) {
      padding: 0 0 0 39px;
    }
  }

  .fileText {
    white-space: pre-line;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    &.docPadding {
      padding-left: 29px;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }

    a {
      opacity: 0.9;
      color: colors.$primaryColor;

      .docIcon {
        margin-bottom: -6px;
        width: 24px;
        height: 24px;

        @media (max-width: break-points.$sm) {
          margin-bottom: -4px;
          width: 18px;
          height: 18px;
        }
      }

      .answerFile {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 105px;

        &.light {
          color: colors.$textColorLightMode;
        }

        &.dark {
          color: colors.$lightThemeColor;
        }
      }
    }
  }
}

.messageContent {
  width: 100%;
  word-wrap: break-word;
  // word-break: break-all;
  overflow-wrap: break-word;

}

.imagesContainer {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  flex-direction: row-reverse;
  -webkit-overflow-scrolling: touch;

  &.imageContent {
    margin: 0 5px 7px 98px;

    @media (max-width: break-points.$lg) {
      margin: 0 5px 7px 69px;
    }

    @media (max-width: break-points.$sm) {
      margin: 0 0 7px 63px;
    }
  }

}

.image {
  border-radius: 10px;
  width: 200px;
  height: 132px;
  @media (max-width: 370px) {
    width: 165px;
  height: 125px;
  }
}

.additionalClass {
  padding-right: 98px;
  padding-left: 98px;
  padding-bottom: 10px;
  margin-top: -5px;

  @media (max-width: break-points.$lg) {
    padding-left: 76px;
    padding-right: 77px;
    margin-top: -10px;
  }
  @media (max-width: break-points.$sm) {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: -10px;
  }
}

.additionalContainer {
  margin-top: -26px !important;
  margin-right: -40px !important;
  display: flex;
  flex-direction: row-reverse;
  padding-left: 10px;

  @media (max-width: break-points.$lg) {
    padding-left: 0px;
    margin-right: -24px !important;
  }
  @media (max-width: break-points.$sm) {
    padding-left: 0px;
    margin-right: -24px !important;
  }
}

.fileContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  padding-left: 0px;
  padding-top: 10px;

  @media (max-width: break-points.$lg) {
    padding-left: 32px;
  }
  @media (max-width: break-points.$sm) {
    padding-left: 32px;
  }
}

.fileDisplay {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  width: 200px;
  position: relative;
  flex: 0 1 calc(30% - 10px);
  margin: 5px;
  box-sizing: border-box;

  &.light {
    background-color: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    color: black;
  }

  &.dark {
    background-color: #252526;
    ;
    box-shadow: 0 0 2px rgba(74, 74, 74, 0.1);
    color: white;
  }

  @media (max-width: break-points.$sm) {
    flex: 0 1 calc(80% - 10px);
    width: 190px;
  }

  @media (max-width: 350px) {
    flex: 0 1 calc(80% - 10px);
    width: 170px;
    padding: 7px;
  }
}

.fileIcon {
  width: 36px;
  height: 36px;
  padding-left: 5px;
  margin-top: 10px;
}

.fileInfo {
  flex-grow: 1;
  margin-left: 8px;
  padding-bottom: 13px;

}

.fileName {
  font-size: 11px;
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: break-points.$sm) {
    font-size: 12px;
    font-weight: bold;
    width: 78px;
  }
}

.fileType {
  font-size: 11px;
  color: gray;
  line-height: 0px;
  text-transform: uppercase;
}

.additionalimages {
  margin-left: 0px !important;

  @media (max-width: break-points.$lg) {
    margin-left: 0px !important;
  }

  @media (max-width: break-points.$sm) {
    margin-left: 0px !important;
  }

}

.copyicon {
  cursor: pointer;

  svg {
    & path {
      fill: white !important;
    }
  }
}

.sharedContainer {
  margin-bottom: -18px;
}

.sharedimage {
  padding-bottom: 0px;
}

.sharedimageContainer {
  padding-bottom: 7px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animatespin {
  animation: spin 1s linear infinite;
}

.assistantIcon {
  background-color: #F6F6F6;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  border-radius: 30px;
  padding: 7px;
}



.QuestionContainer{
  width: 100%;
  padding: 12px 40px 10px 102px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;

  &.threads{
    padding: 0 5px 8px 31px;
    @media (max-width: break-points.$sm) {
      padding: 0 8px 8px 14px;
    }
  }

  @media (max-width: break-points.$lg) {
    padding: 12px 20px 10px 79px;
  }
  @media (max-width: break-points.$sm) {
    padding: 10px 23px 10px 85px;
  }
}
.questionleftContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: end;

  &.isEdit{
    justify-content: space-between;
  }
  &.threads{
    gap: 8px;
  }
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24.13px;
  height: 24.13px;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 5px;
  &.light {
     background: #F6F6F6;
  }

  &.dark {
    background: #252526;
  }
  &.threads{
    background:transparent !important;
  }
}
.copyContainer{
  margin-left: 12px;
}
.CopyIconContainer{
margin-right: -5px;
}
.questionIconContainer{
  padding: 0px 97px 10px 98px;

  @media (max-width: break-points.$lg) {
    padding: 2px 77px 10px 70px;
  }
  @media (max-width: break-points.$sm) {
    padding: 0px 80px 10px 64px;
  }
  &.threads{
    padding: 0px 0px 10px 0px;
    gap: 14px;
    @media (max-width: break-points.$lg) {
      padding: 0px 0px 10px 0px;
    }
    @media (max-width: break-points.$sm) {
      padding: 0px 0px 24px 0px;
    }
  }
}

.videoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity 0.3s;
}

.pauseIcon{
  font-size: 19px;
  background-color: rgb(212, 212, 212);
  
  border-radius: 50%;
  padding: 2px 7px;
}

.playIcon:hover {
  opacity: 0.8;
}

.vDisplay {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 10px;
  
    &.light{
      background-color: #fff;
      border: 1px solid #EBEBEB;
    }
    &.dark{
      background-color: transparent;
      border: 1px solid #A09FA2;
    }
}

.loader {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;

  &.light{
    background-color: #fff;
    border: 1px solid #EBEBEB;
  }
  &.dark{
    background-color: transparent;
    border: 1px solid #2D2D2D;;
  }
}
.additionalThread {
  padding-right: 45px;
  padding-left: 31px;
  padding-bottom: 8px;
  margin-top: -5px;

  @media (max-width: break-points.$sm) {
    padding-right: 50px;
    padding-left: 14px;
  }
}